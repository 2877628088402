<template>


 <div id="background"  @click="close" >


    <div id="content">


        <img id="modal-image" :src="require('@/assets/' + '/img/frisbee.jpg')" alt="">
        <span>&times;</span>

    </div>


 </div>



</template>


<script>

export default {
    methods: {
      close() {
        this.$emit('close');
      },
    },
}

</script>


<style scoped>

#background{
    position: fixed;
    top:0;
    left:0;
    width:100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;

    display: flex;
    align-items: center;
    justify-content: center;
    /* overflow: auto; */
}

#content{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    /* overflow: auto; */

}

img {
    width: min(350px, 60vw);

   
    border: 8px solid white ; 
}

span{
   display: block;

   position: absolute;

   font-size: 3em;
   font-weight: 600;

   top:-1em;
   right: -1em;

   color:white;
   z-index: 1000;

   cursor:pointer;

   transition: all 0.25s ease-in-out;


}

span:hover{
    transform: scale(110%);
}



</style>