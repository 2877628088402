<template>
  <div id="container">

    <div class="content2">





      <div id="title">




        <div id="pic" style="width:170px;">
          <img src="../src/assets/img/me.png" alt="">
        </div>

        <h1 style="font-weight: 900; font-family:'Inter Tight'; font-size: 2.5em;">Aidan Goodyer</h1>
        <h4 style="font-weight: 600;">Software Engineering Student</h4>
        <p style="max-width:70%;"> I build innovative, reliable software systems using Engineering Principles.  </p>




        <div id="sections">

          <div class="tab active" id="tab-about">
            <div></div>
            <a href="#about">ABOUT</a>
          </div>

          <div class="tab" id="tab-experience">
            <div></div>
            <a href="#experience">EXPERIENCE</a>
          </div>

          <div class="tab" id="tab-projects">
            <div></div>
            <a href="#projects">PROJECTS</a>
          </div>

        </div>

        <div id="links">

          <a href="https://www.linkedin.com/in/aidan-goodyer/">
            <img src="../src/assets/social/linkedin.svg" alt="LinkedIn Icon">
          </a>


          <a href="https://github.com/agoodyer">
            <img src="../src/assets/social/github.svg" alt="GitHub Icon">
          </a>
          <a href="mailto:aidangoodyer@gmail.com">
            <img src="../src/assets/social/email.svg" alt="Email Icon">
         
          </a>


        </div>

      </div>

    </div>

    <div class="content">


      <div id="wrapper" style="width:100%; display: flex; flex-direction: column; align-items: center;">

        <div class="section" id="about">

          <h3 style="text-align: left;width: 60%; font-weight: 900;" id="about">ABOUT ME</h3>

          <div style="text-align: left; margin-bottom: 1em; ">
            <p>

              I first discovered that I liked to code almost a decade ago, 
              moving an 
              <a id="scratch" href="https://en.wikipedia.org/wiki/Scratch_(programming_language)"> 
              <b >Orange Cat</b></a> 
              around on my screen. Since then, I've poked my head around every corner of the STEM field, 
              but I never lost my enthusiasm for creating and building things with my keyboard.  



              
            </p>

            <p style="padding-top: 1.5em;">
             Now, I'm a fourth year Software Engineering student at<a id="maclink" href="https://www.mcmaster.ca/"><b> McMaster University. </b> </a> 
              I've left the orange cats behind for now, but I get to work on much cooler things,  
             like Concurrent Systems, Databases, and Machine Learning models.  
            </p>


            <p style="padding-top: 1.5em;">
             In my free time, I enjoy playing <b @click="showModal" id="frisbee"> Intramural Ultimate Frisbee </b>, 
             and I create workshops to teach undergraduate students machine learning fundamentals
              as an executive team member of the <a id="macai" href="https://mcmasterai.com"><b> McMaster AI Society</b></a>. I also frequently attend hackathons and engineering design competitions 
             to learn and build new skills. 
            </p>


            <ImageModal v-show="isModalVisible" @close="closeModal" ></ImageModal>
          


            <p style="padding-top: 1.5em;">
                  Throughout my Software Engineering journey I've gained experience 
                  as a leader and an effective team-member, 
                  all while developing numerous technical skills, 
                  many of which are listed below. 
            </p>



          </div>


          <div id="skill-icons" style="margin-bottom: 1em; 
            display: flex; 
            flex-direction: row; 
            flex-wrap: wrap; 
            justify-content: center; 
            align-items: top;">
            <SkillIcon name="cpp" skill="C++" />
            <SkillIcon name="python" skill="Python" />
            <SkillIcon name="git" skill="Git" />
            <SkillIcon name="go" skill="Go" />
            <SkillIcon name="html" skill="HTML" />
            <SkillIcon name="java" skill="Java" />
            <SkillIcon name="javascript" skill="JavaScript" />
            <SkillIcon name="tensorflow" skill="TensorFlow" />
            <SkillIcon name="vue" skill="Vue.js" />
            <SkillIcon name="react" skill="React" />
            <SkillIcon name="azure" skill="Azure" />
            <SkillIcon name="postgresql" skill="PostgreSQL" />
          </div>

        </div>


        <div class="section" id="experience">

          <h3 style="text-align: left; font-weight: 900;" id="experience">EXPERIENCE</h3>

          <ExperienceItem 
          link="https://www.scotiabank.com/ca/en/personal.html" 
          date="JAN-DEC 2024"
          title="Software Engineer Intern - Scotiabank " 
          :skills="['Java', 'Go', 'Python', 'SQL', 'Selenium']"
          :img="require('@/assets/' + '/img/logos/scotiabank.png')" 
          desc="Developed novel data transformation processes for ETL pipelines with Python and SQL. Lead development of a regression test suite using Java and Selenium. Currently working on the Cloud Accelerator team to help teams deliver software with high velocity. 🚀 ">
          </ExperienceItem>


          <ExperienceItem 
          link="https://www.mcmasterai.com/" 
          date="2023-PRESENT"
          title="Workshop Developer - McMaster AI Society " 
          :skills="['Tensorflow', 'Python', 'Pytorch', 'SKLearn']"
          :img="require('@/assets/' + '/img/logos/macai.svg')" 
          desc=" Preparing and presenting bi-weekly workshops on fundamental machine learning topics. Develop code demos to teach the
                basics of common machine learning libraries in Python, including PyTorch, Sci-kit Learn, and TensorFlow.">
          </ExperienceItem>

          <ExperienceItem 
          link="https://www.blackberry.com/us/en" 
          date="APR-AUG 2022"
          title="Unified Communications - BlackBerry " 
          :skills="['JavaScript', 'Vue.js', 'SQL']"
          :img="require('@/assets/' + '/img/logos/blackberry.png')" 
          desc="Assisted in the development of an internal supervisor tool using Vue.js, JavaScript and Nuxt.js to oversee work from
                home employees and their statuses. Performed REST API requests to fetch and update, and display employee data.">
          </ExperienceItem>


        </div>


        <div class="section" id="projects">
          <h3 style="text-align: left;width: 60%; font-weight: 900;" id="projects">PROJECTS</h3>


          <ProjectItem 
          title="3D Raytracing Engine" 
          link="https://github.com/agoodyer/raytracer"
          :img="require('@/assets/' + '/img/projects/raytrace.png')"
          :skills="['Go', 'Concurrency', 'Data Structures & Algorithms', 'Git']" 
          desc="Created my own 3D Raytracer using only the Go Standard Library. Utilized Bounding Volume Hierarchies and Multithreading to achieve a 36x speedup over naive approach. ">
          </ProjectItem>

          <ProjectItem 
          title="TicTacToeBot" 
          link="/TicTacToeBot.pdf"
          :img="require('@/assets/' + '/img/projects/tictac.png')"
          :skills="['C++', 'ESP32', 'Arduino', 'Robotics']" 
          desc="Developed a fully-wireless remote operated robot to compete in the 2023 MEC Senior Design Challenge. Achieved fastest time-to-complete of any competing team.">
          </ProjectItem>

          <ProjectItem 
          title="Procedural Island Generator" 
          link="https://github.com/2AA4-W23/a4-urbanism-agoodyer"
          :img="require('@/assets/' + '/img/projects/island.jpg')"
          :skills="['Java', 'Maven', 'JUnit', 'Git']" 
          desc="Developed a program to generate procedural islands via a Voronoi mesh, exportable to SVG or OBJ formats.">
          </ProjectItem>


          <ProjectItem 
          title="S:\HA<KS Hackathon" 
          link="https://www.scotiabank.com/careers/en/careers/s-hacks.html#:~:text=S%3A%2FHacks%20is%20Scotiabank's,core%20of%20S%3A%5CHacks!"
          :img="require('@/assets/' + '/img/projects/shacks.jpg')"
          :skills="['JavaScript', 'Figma', 'Design', 'Leadership' ]"
          desc="Awarded second place overall at S:\HA<KS 2023 for our app prototype, GreenQuest. Returned in 2024 as a volunteer and mentor to guide a new group of hackers to victory!    ">
          </ProjectItem>



          <ProjectItem 
          title="Minesweeper" 
          link="https://minesweeper.agoodyer.com"
          :img="require('@/assets/' + '/img/projects/minesweeper.png')"
          :skills="['JavaScript', 'Firebase', 'P5.js']"
          desc="A recreation of the classic game Minesweeper, complete with a global leaderboard built using Firebase Firestore.  ">
          </ProjectItem>


          <ProjectItem 
          title="Spotify Higher/Lower Game" 
          link="https://higherlower.agoodyer.com/"
          :img="require('@/assets/' + '/img/projects/higherlower.png')"
          :skills="['JavaScript', 'Vue.js', 'Spotify API']"
          desc="Created a game leveraging the Spotify API to let players guess how their favourite artists rank on Global Charts.">
          </ProjectItem>

        </div>


        <div
          style="display: flex; justify-content: center; align-items: center; color:lightgrey; font-family: monospace; padding-block: 7em; flex-direction: column;">
        
          <p> Created by Aidan Goodyer</p>

          <img style="width: 40px; filter: grayscale(100%); opacity: 20%;" src="../src/assets/img/ag.png" alt="Site Logo">

          <p>2024</p>
        </div>

      </div>




    </div>

  </div>

</template>

<script>


import ExperienceItem from './components/ExperienceItem.vue';

import ProjectItem from './components/ProjectItem.vue';

import SkillIcon from './components/SkillIcon.vue';

import ImageModal from './components/ImageModal.vue'; 

export default {
  name: 'App',
  components: {
    ExperienceItem,
    ProjectItem,
    SkillIcon, 
    ImageModal
  },

  data() {
      return {
        isModalVisible: false,

      };
    },
    methods: {
      showModal() {
        this.isModalVisible = true;
      },
      closeModal() {
        this.isModalVisible = false;
      }
    }
}


window.onscroll = () => {

  let section = document.querySelectorAll(".section");
  let tabs = document.querySelectorAll('.tab');


  section.forEach(sec => {
    let top = window.scrollY;
    let offset = sec.offsetTop;
    let height = sec.offsetHeight;
    let id = sec.getAttribute('id')

    if (top >= (offset - 200) && top < offset + height) {
      tabs.forEach(links => {
        links.classList.remove('active');
        document.querySelector('#tab-' + id).classList.add('active')
      })
    }
  })

}

</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Source+Code+Pro:wght@900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter+Tight:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');


#about a {
  transition: color 0.25s ease-in-out; 
    text-decoration:none;
    color:inherit;
}


#frisbee{
  display:inline-block;
cursor: pointer;
/* color:red; */

transition: all ease-in-out 0.25s;

}

#frisbee:hover{
  transform: scale(105%) rotateY(16deg);

}

#scratch {
    cursor: url('assets/ui/scratch.png'), cell;
}

#macai:hover{
  color:#338cf5;
}
#maclink:hover{
  color: #7A003C; 
}

#scratch:hover{
  color: orange;
}




body {
  background-color: rgb(246, 249, 251);
}

#app {
  margin-top: 3em;
  font-family: Inter;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: rgb(63, 61, 86);
  background-color: rgb(246, 249, 251);

}

#container {
  display: grid;
  grid-template-columns: 5fr 7fr;
  /* align-items: center; */
  justify-content: center;
  flex-direction: row;
  /* height: 100vh; */
  justify-content: center;
}





.content {
  padding-top: 4em;
  /* width: 100%; */
  display: flex;
  justify-content: center;

}

.section {
  width: 66%;

  /* min-width: 350px; */

  display: flex;
  flex-direction: column;
  justify-content: center;

  padding-top: 2em;

  padding-right: 4em;


  /* min-height: 70vh; */

}

/* *{
  border: 1px solid red; 
} */

.content2 {
  top: 0;
  position: sticky;


  display: flex;
  justify-content: center;

}

/* * {
  outline: 1px solid red;
} */

#pic img {
  width: 100%;
  border-radius: 500px;
  transform: translateX(20%);
}

#title h1,
h4,
p {
  padding: 0;
  margin-block: 0.5em;
  text-align: left;
}

#title {
  top: 3em;
  position: sticky;
  padding-block: 2em;
  align-items: center;

  width: 65%;
  justify-content: left;
  height: 10%;
}

#sections {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: 3em;
}

#skill-icons img {
  margin: 0.5em;
  transition: all ease-in-out 0.25s;
}

#skill-icons img:hover {
  transform: translateY(-14%) rotateY(16deg);

}


.tab div {

  width: 30px;
  height: 1px;
  margin-right: 10px;
  background-color: #3f3d56;
  transition: ease-in-out 0.25s;


}

.tab {
  opacity: 0.6;
  transition: ease-in-out 0.25s;
}

.active {
  opacity: 1;
}

.active div {

  width: 60px;

  opacity: 100%;

}

.content2 {
  /* margin-left: 50px; */
}

.tab:hover div {
  width: 60px;




}

.tab:hover {
  opacity: 1;
  /* width: 200%; */
}


.tab a {
  text-decoration: none;
  color: rgb(63, 61, 86);

}


.tab {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-block: 0.5em;
  font-size: small;
  font-weight: 800;
}

#links {
  /* width: 30%; */
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  margin-top: 6em;
}



#links img {
  height: 1.5em;
  width: 1.5em;
  margin-right: 1.5em;
}


#links img:hover {
  /* filter: opacity(60%); */

  filter: brightness(0) saturate(100%) invert(47%) sepia(14%) saturate(4214%) hue-rotate(186deg) brightness(101%) contrast(102%);
}


.grey-line {
  height: 1px;
  width: 20%;
  border-radius: 3em;
  background-color: #3f3d56;
  margin-block: 3em;
}



::selection {
  color: white;
  background-color: #00b28b;
}

html {
  scroll-behavior: smooth;
}



@media only screen and (max-width: 900px) {
  .section {
    width: 85%;
    padding-right: 0;
  }
}


@media only screen and (max-width: 700px) {
  #container {
    grid-template-columns: none;
    /* justify-items: center; */
  }

  .content2 {
    justify-content: left;
    padding-inline: 2em;

  }

  #title {
    width: 100%;
  }

  .content2,
  #title {
    position: relative;
  }

  .content {
    width: 100%;
    display: flex;
    align-items: center;
  }


  .content2 h1 {
    justify-content: center;
    text-align: center;

  }

  #pic img {
    transform: none;
  }



  #sections {
    display: none;
  }

  #links {
    margin-top: 2em;
  }

  #app {
    margin-top: 0;
  }







}
</style>
