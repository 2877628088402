<template>


<div id="skill-container">

<img :src="require('@/assets/' + '/img/skills/' + name + '.svg')" alt="" style="width:30px">
<div class="skill"> {{skill}} </div>    
</div>



</template>


<style scoped>

#skill-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    width: fit-content;
    transition: all 0.25s ease-in-out;

}


 img {
  margin: 0.5em;
  transition: all ease-in-out 0.25s;
}

img:hover{
  transform: translateY(-14%) rotateY(16deg);
  
 
}

img:hover ~ .skill{
    opacity: 100%;
}


.skill{
    position: absolute; 

    z-index: 99;
    top: 3.5em;
    width: fit-content;

  color: #00b28b;
  background-color: #ddf2ef; 
  border-radius: 3em;
  font-size:0.75em;
  font-weight: 500;
  /* width:fit-content; */
  padding: 6px;
  padding-inline: 8px;

   border:0.1em solid #00b28b;

   display: inline-flex;

   justify-content: left;

   /* display: inline; */
   flex-direction: row;


  opacity: 0;

  left:50%; 
  transform: translateX(-50%);
  transition: all 0.25s ease-in-out;
   
}
</style>

<script>

export default {
  name: 'SkillIcon',
  props: {
    skill: String,
    name: String
  }
}
</script>