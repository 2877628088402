<template>
  <a :href="link">
<div id="item-container">

 <div id="date">

  <!-- <p> -->


  <div style="text-align: center; padding: none; margin: none;">
    {{ date }}
  </div>
    
    <!-- </p> -->


 <img id="logo"  :src="img" alt="">
 </div> 

 <div id="info">


  <div id="name" style="display: flex; flex-direction: row; gap:0.5em; align-items: center;">
    <h4 style="padding:0; margin:0;text-align: left;">{{ title }}</h4>
    <img src="../assets/ui/link.svg" alt="" style="height: 1em; transition: ease-in-out 0.15s;">
  </div>
  
  <p style="margin-bottom: 0;">{{ desc }}</p>

  
  <div id="skills">
    <ul>
  <li v-for="skill in skills" :key="skill">
    <div class="skill"> {{ skill }}</div>
  </li>

</ul>
  </div>


   </div>


</div>
</a>

</template>

<style scoped>



#item-container{
  /* width: 60%; */
  /* background-color: red; */

  
  display: flex;

  flex-direction: row;

  justify-content: left;

  padding: 1em;


  gap:1em;

  transition: ease-in-out 0.15s;
  border-radius: 0.4rem;

  border-width: 0.1em;
  border-style: solid;
  border-color: rgba(0,0,0,0);
}


#logo{

  /* style="height:60px; padding:1em;" */

  height: 60px;
  padding:1em;

  object-fit: contain;

}

#item-container #name:hover {
  color: #338cf5
}

#item-container:hover{
  box-shadow: 0 4px 6px -1px rgba(0,0,0,0.04),0 2px 4px -1px rgba(0,0,0,0.03);
  border-color:  rgb(230,230,230);
}

#item-container:hover #name img{
  transform: translateX(2px) translateY(-2px) ;
}

#date{
  font-weight: 700;
  font-size: 0.85em;
  font-family: 'Inter';


  min-width: max(20%,90px);

  /* min-width: 30%; */

  

  padding-block: 0.2em;

  /* opacity: 0.6; */

  display: flex;

  flex-direction: column;



}


#info{
  /* padding: 2em; */
  border-radius: 0.4rem;
  /* border:0.1em solid rgb(230,230,230); */
  
}

a{
  text-decoration: none;
 color: inherit;
 display: inline-block; 
 width: 100%;
 position: relative;

 display: flex;
  flex-direction: row;

  justify-content: center;


}

li {
 
  display:inline;
}
ul{
  display: inline;
  list-style-type: none;
  padding: 0;
  gap: 1em;
}

.skill{
  color: #00b28b;
  background-color: rgba(0, 178, 139,0.1); 
  border-radius: 3em;
  font-size:0.75em;
  font-weight: 500;
  /* width:fit-content; */
  padding: 6px;
  padding-inline: 8px;

   border:0.1em solid #00b28b;

   display: inline-flex;

   justify-content: left;

   /* display: inline; */
   flex-direction: row;
   margin-inline: 0.4em;

   margin-bottom:0.5em;

   
}
#skills{
  display: flex;
  flex-direction: row;
  justify-content: left;
  padding: 0;
  margin: 0;
}



@media only screen and (max-width: 600px) {

#item-container{
 /* background-color: red; */
 flex-direction: column;
 gap:0; 
}

#logo {

  height: 90px;
  margin-bottom: 2em;

}

#date{
  flex-direction: column-reverse;
  padding-block: 0;
}




}




</style>


<script>
export default {
  name: 'ExperienceItem',
  props: {
    title: String,
    skills: Array,
    date:String,
    desc: String,
    img:String, 
    link:String

  }
}



</script>